import { Container, Navbar, Nav } from 'react-bootstrap';
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Link } from "gatsby"
import 'bootstrap/dist/css/bootstrap.min.css';
import { useStaticQuery, graphql } from "gatsby";
import $ from "jquery";
import { Search, SearchWhite, LogoMain, LogoReid, Myaccount, Phone } from '../icon';
import Searchpopup from '../searchOverlay/SearchOverlay'

const Header2 = ({ siteTitle, calendly, phonecall,Enquire_Now }) => {
  const data = useStaticQuery(graphql`
query BurgerQuery2 {  
  allStrapiAllMenus(sort: {fields: Sort, order: ASC}) {
    edges {
      node {
        Label
        URL
        Secondary_URL
        Link_Type
        Show_In_Burger_Menu
        Button_Class
        main_parent {
          Label
          URL
        }
        sub_parent {
          Label
          URL
        }
      }
    }
  }

  allStrapiMenuBurger(filter: {Publish: {eq: true}}, sort: {fields: Sort, order: ASC}) {
    edges {
      node {
        Parent_Label
        Sub_Menus {
          Parent_Label
          Add_Sub_Menus {
            Label
            Custom_URL
          }
        }
      }
    }
  }

}
`)
const topBurgerMenu = data.allStrapiMenuBurger.edges;

function searchOverlay(e) {
  $("body")
      .addClass("modal-open")
      .append("<div class='search-modal modal-backdrop fade'></div>")
  $(".search-overlay").addClass("active-search")
}
  if(typeof window !== 'undefined') {
    document.querySelector('body').classList.remove('stop-overflow')
    document.querySelector('body').classList.remove('modal-open')
  }

  const manageClick = () => {
    let clopase = document.querySelector(".collapse")  //basic-navbar-nav 
    clopase.classList.remove("show")
    document.querySelector('body').classList.remove('stop-overflow')
    $('.navbar-collapse').removeClass('mobile-menu-wrapper');

  }
  const showMenu = () => {
    $('.navbar-collapse').addClass('mobile-menu-wrapper');
    $('.mobile-menu-wrapper .navbar-nav').addClass('animate__animated animate__slideInRight');
    let clopase = document.querySelector(".collapse");
    clopase.classList.add("show")
    document.querySelector('body').classList.add('stop-overflow')

  }

  // Mobile menu toggle
  useEffect(() => {
    $(".mobile_nav_menu").each(function () {
      var $this = $(this);
      var subMenu = $this.find('> ul').length;

      if (subMenu) {
        $this.addClass("sub_menu_exist");
      }
    })

    $(".mobile_nav_menu").click(function () {
      //console.log('aaaaaaaaaaa', $(this)[0].className)
      var checkactive = $(this)[0].className;
      if(checkactive.indexOf('active') != -1){
        $(".mobile_nav_menu.sub_menu_exist").removeClass('active');
        $('.sub-nav').stop(true, true).slideUp();
        console.log(checkactive + " found");
       } else {
        console.log(checkactive + "not found");
        $(".mobile_nav_menu.sub_menu_exist").removeClass('active');
        $('.sub-nav').slideUp();
        $(this).toggleClass("active");
        $(this).find('.sub-nav').stop(true, true).slideDown();

       } 
      //$(this).toggleClass("active");
    })

  }, []);

  // const menuClick = (e) => {
  //   alert("test")
  // }

  return (
    <>
  <Searchpopup/>
    <header className="header2 fixed-top">
      <Container>
        <Navbar className="p-0" expand="xl">
        <Link to="/">
          <Navbar.Brand className="p-0" >
            <LogoMain /> <LogoReid />
          </Navbar.Brand>
        </Link>
          {/* <Navbar.Toggle className="p-0" aria-controls="basic-navbar-nav" /> */}
          <button className="p-0 navbar-toggler"
            onClick={
              e => showMenu()
            }
          >
            <span className="navbar-toggler-icon">&nbsp;</span>
          </button>

          <button className="btn-search d-xl-none"

          >
            {!phonecall &&
              <a href="javascript:;" onClick={searchOverlay}>
              <SearchWhite />
              </a>
            }
          
            {phonecall &&
             <a href={`tel:${phonecall}`}>
             <Phone />
             </a>
            }

          </button>
          <Navbar.Collapse id="basic-navbar-nav">
            <button className="close-nav d-xl-none"
              onClick={
                e => manageClick()
              }
            >
              <span>&nbsp;</span>
              <span>&nbsp;</span>
            </button>
            <Nav>
              <ul className="navigation d-xl-flex align-items-xl-center">
                {data.allStrapiAllMenus.edges.map(({ node }, key) => {
                  return <>
                    {node.Show_In_Burger_Menu === true && node.Button_Class == null &&
                      <>
                      {node.Label == 'Services' ?
                          <li className="mobile_nav_menu services-megamenu">
                            <Link to={`/${node.URL}/`} className={`nav-link ${node.Button_Class}`}>{node.Label}</Link>
                            <ul className="sub-nav first-ul">
                              {topBurgerMenu.map(menu => {
                                return <>
                                  {menu?.node?.Sub_Menus.map(submenu => {
                                    return <>
                                      <li className="submenu-head">
                                        {submenu.Parent_Label}
                                        <ul className="sub-nav submenu-nav second-ul">
                                          {submenu?.Add_Sub_Menus.map(addsubmenu => {
                                            console.log('checkmenuadd2', addsubmenu);
                                            return <>
                                              <li className="submenu-link"><Link to={`${addsubmenu.Custom_URL}`}>{addsubmenu.Label}</Link></li>
                                            </>
                                            })
                                          }
                                        </ul>
                                      </li>
                                    </>
                                    })
                                  }
                                </>
                                })
                              }
                            </ul>
                          </li>
                      :
                      <li className="mobile_nav_menu">
                        {node.Label == 'Search' &&
                          <Link to="/properties-for-sale-in-london-and-manchester/" className={`nav-link ${node.Button_Class}`}>{node.Label}</Link>
                        }
                        {node.Label != 'Search' && node.Secondary_URL != null && node.Secondary_URL != '' &&
                          <Link className="nav-link" to={`${node.Secondary_URL}/`}>{node.Label}</Link>
                        }
                        {node.Label != 'Search' && !node.Secondary_URL &&
                          <Link to={`/${node.URL}/`} className={`nav-link ${node.Button_Class}`}>{node.Label}</Link>
                        }
                        {node.Label != "Marketing your Property" && node.Label !="Manchester" &&
                          <ul className="sub-nav">
                            {node.Label == 'Search' &&
                              <>
                                <li><Link to="/properties-for-sale-in-london-and-manchester/">Property for sale</Link></li>
                                <li><Link to="/properties-to-rent-in-london-and-manchester/">Property to rent</Link></li>
                              </>
                            }
                            {data.allStrapiAllMenus.edges.map((submenu, key2) => {
                              return <>
                                {submenu.node.sub_parent == null && submenu.node.main_parent && (submenu.node.Label != "Help to Buy")  && (submenu?.node?.Label != "Preston")  && submenu.node.main_parent.Label === node.Label && submenu.node.Secondary_URL != null &&
                                  <li><a href={`${submenu.node.Secondary_URL}/`} target={submenu.node.Link_Type == 'External_New_Window' && 'blank'}>{submenu.node.Label}</a></li>
                                }
                                {submenu.node.sub_parent == null && submenu.node.main_parent && (submenu.node.Label != "Help to Buy")  && (submenu?.node?.Label != "Preston")  && submenu.node.main_parent.Label === node.Label && submenu.node.Secondary_URL == null &&
                                  <li><Link to={`/${node.URL}/${submenu.node.URL}/`}>{submenu.node.Label}</Link></li>
                                }
                              </>
                            })}

                          </ul>
                        }
                      </li>
                      }
                      </>
                    }
                    {node.Show_In_Burger_Menu === true && node.Button_Class != null && !Enquire_Now && 
                      <li>
                        <Link to={`/${node.URL}/`} className="btn">{node.Label}</Link>
                      </li>
                    }
                  </>
                })}
                {Enquire_Now &&
                      <li>
                        <Link to={'/investment-enquiry/'} className="btn">Enquire Now</Link>
                      </li>
                }
                <li>
                <a href="javascript:;" onClick={searchOverlay} class="avatar-icon">
                    <Search />
                  </a>
                </li>
                  <li><a href="https://orlandoreid.propertyfile.co.uk/" target="_blank">
                    <Myaccount/>
                    </a>
                </li>
              </ul>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </Container>
    </header>
    </>
  )
}
Header2.propTypes = {
  siteTitle: PropTypes.string,
}

Header2.defaultProps = {
  siteTitle: ``,
}

export default Header2
