import React, { useState, useEffect } from "react";
import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap';
import { navigate }  from "gatsby"
import { Link } from "gatsby"
import $ from "jquery";
import Select from "react-select";
import { Search} from '../icon';

import "./assets/styles/_index.scss"

const SearchOverlay = (props) => {

    function closeSearchOverlay(e) {
        $("body").removeClass("modal-open").find(".modal-backdrop").remove()
        $(".search-overlay").removeClass("active-search")
    }

    // $('.search_buy').click(function(){
    //     var conceptName = $('#aioConceptName :selected').val()+'/sales';
    //     navigate('/contact/general-enquiry?contact=officecontact')

    // })
    if(typeof window !== 'undefined') {
        $('#aioConceptName').change(function() {
            var $option = $(this).find('option:selected');
            var selvalue = $option.val();//to get content of "value" attrib
            if(selvalue == "/property/help-to-buy/") {
                $('.search_rent').hide();
            } else {
                $('.search_rent').show();
            }
        });
    }
    const SearchFunsales = () => {
        
        var conceptName = $('#aioConceptName :selected').val()+'for-sale';
        var searchinput = $('#searchinput').val();
        let str = searchinput?.trim();
        let fixedStr = str?.replace(/\s+/g, ' ');
        var searchURL = ''
        if(fixedStr) {
            searchURL = conceptName+'/in-'+fixedStr?.split(" ")?.join("-")?.toLowerCase()
        }
        else if (conceptName == "/property/for-sale") {
            searchURL ='/properties-for-sale-in-london-and-manchester'
        }
        else {
            searchURL = conceptName+'/in-london-and-manchester'
        }
        $("body").removeClass("modal-open").find(".modal-backdrop").remove()
        $(".search-overlay").removeClass("active-search")
        if (!searchURL.endsWith('/'))
            searchURL = searchURL + '/';
        navigate(searchURL)
    }
    
    const SearchFunrentals = () => {
        
        var conceptName = $('#aioConceptName :selected').val()+'to-rent';
        var searchinput = $('#searchinput').val();
        let str = searchinput?.trim();
        let fixedStr = str?.replace(/\s+/g, ' ');
        var searchURL = ''
        if(fixedStr) {
            searchURL = conceptName+'/in-'+fixedStr.split(" ").join("-").toLowerCase()
        } else if (conceptName == "/property/to-rent") {
            searchURL ='/properties-to-rent-in-london-manchester-nottingham'
        } else {
            searchURL = conceptName+'/in-london-and-manchester'
        }
        $("body").removeClass("modal-open").find(".modal-backdrop").remove()
        $(".search-overlay").removeClass("active-search")
        if (!searchURL.endsWith('/'))
            searchURL = searchURL + '/';
        navigate(searchURL)
    }
    
    

      
    return(
        <>

            <div className="search-overlay">
                <Link to="#" onClick={closeSearchOverlay}><i className="icon icon-search-close"></i></Link>
                <Container className="property-search-overlay_vertical_align">
                    <Row>
                        <Col lg={10}>
                            <Tabs  id="uncontrolled-tab-example" className="search-tab">
                                {/* <Tab eventKey="sitesearch" title="Site Search">
                                    <input type="text" placeholder="Search the website" name="search" className="form-control search-overlay-input" />
                                </Tab> */}
                                <Tab defaultActiveKey="sitesearch" eventKey="propertysearch" title="Property Search">
                                    <div className="input-group mb-3 property-search-overlay">
                                        <select className="search-select" id="aioConceptName">
                                            <option value="/property/">Residential</option>
                                            <option value="/property/new-homes/">New Homes</option>
                                            <option value="/property/help-to-buy/">Help to Buy</option>
                                        </select>
                                        <div className="input-group-prepend property-search-width">
                                            <input id="searchinput" type="text" placeholder="Postcode, Town or City" name="search" className="form-control search-property-input" />
                                        </div>
                                        <div className="property-search-btn_wrapper">
                                            <a href="javascript:;" onClick={()=> SearchFunsales()} className="btn search_buy">Buy</a>
                                            <a href="javascript:;" onClick={()=> SearchFunrentals()} className="btn search_rent">Rent</a>
                                        </div>
                                    </div>
                                </Tab>
                            </Tabs>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default SearchOverlay